import { Link } from "react-router-dom";
import '../Styles/Asana.css';
import "../Styles/Lesson.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import { addPoseToLEsson } from "../redux/lessonSlice";
import { useDispatch } from "react-redux";

import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода
import { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";

//icons
import { IoAddOutline } from "react-icons/io5";
import { BsCheckLg } from "react-icons/bs";
//react-responsive library
import { useMediaQuery } from 'react-responsive'; // импортируем хук для работы с медиа-запросами




const Asana = ( {asana} ) =>{
    //console.log(asana);
    const dispatch = useDispatch(); //"берем" функцию addPoseToLesson из redux
    const { t } = useTranslation(); // Используем хук для получения функции перевода

    //состояние для динамического изменения кнопки ADD TO LESSON 
    const [added, setAdded] = useState(false);
    //функция для данимаческого изменения кнопки на 3 секунды
    const handleClikAdded = () =>{
        dispatch(addPoseToLEsson({asana}));
        setAdded(true);
        setTimeout(()=>{
            setAdded(false);
        }, 1000);
    };

    //функция возврата css стиля цвета кружочка рядом с текстом для слов на кнопках
    const levelColorClass = (level) => {
        switch(level) {
            case "beginner": return "white";
            case "middle": return "whiteBlack";
            case "advanced": return "dark";
            default: return "dark";
        }
    };
    
    // определяем, является ли экран мобильным
    const isMobile = useMediaQuery({ maxWidth: 700 }); 




    return(
        <div className="AsanaCard" >
            <div className="AsanaCardStroke shadow p-3 mb-5 bg-white rounded">
                <div className="LessonAddDurationRemoveButton">
                    <span className={`level-circle ${levelColorClass(asana.level)}`}></span>
                    <Link to={`/about/${asana.title}`} className="Info">
                        <BsInfoCircle  style={{ width: 15, height: 15 }}  />
                    </Link>

                </div>
                <Link to={`/about/${asana.title}`} >
                    <img src={asana.img} alt="asana" className="AsanaImage" />
                </Link>
    
                <p className="AsanaP FontSecond" > {t(`${asana.id}AsanaName`, asana.asanasName)} </p>                 
                <p className="AsanaP FONT" > {asana.asanasNameSanskrit} </p>
            </div>

            <Button 
                variant="dark" 
                className="MobileButton" 
                onClick={handleClikAdded}
                >
                {
                    added ? 
                        (isMobile ? 
                            <BsCheckLg style={{ width: 20, height: 20 }} /> 
                            : t('searchAsanas.addedButton')
                        )
                        : (isMobile ? 
                            <IoAddOutline style={{ width: 20, height: 20 }} /> 
                            : t('searchAsanas.addButton')
                        )
                }
            </Button>

        </div>
    )}
export default Asana


//                <p className="AsanaP" >  {asana.id} </p>   
//{()=>{dispatch(addPoseToLEsson({asana}))} } 