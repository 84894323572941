import Footer from "./Footer";
import { useTranslation } from "react-i18next"
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Donate from "./Donate";





const Blog = () =>{

    const { t, i18n} = useTranslation()


    return(
        
        <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <HelmetProvider>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('metatags.blog.blogTitle')}</title>
                <meta name="description" content={t('metatags.blog.description')} />
            </Helmet>
            </HelmetProvider>

            
            <div style={{ flex: "1" }}>

                <div className="container-fluid col-md-8 featureMargin border-bottom">
                    <h3 className="pb-4 mb-4 fst-italic border-bottom FONT">
                        Blog 
                    </h3>
                    <article className="blog-post">
                        <h2 className="display-5 link-body-emphasis mb-1 FontSecond"> {t("blog.adressingToAll")} </h2>
                        <p className="blog-post-meta FONT">{t("blog.date")} </p>

                        <p className="FontSecond">{t("blog.par1")}</p>
                        <p className="FontSecond">{t("blog.par2")}</p>
                        <h3 className="FontSecond">{t("blog.par3")}</h3>
                        <ul className="FontSecond">
                            <li>{t("blog.par4")}
                                <video autoPlay loop muted style={{ width: '50px' }} className="headlineVideo">
                                    <source src={require("../../myAssets/blogHeart.mp4")} type="video/mp4" />
                                </video> 
                            </li>
                            <li>{t("blog.par5")}</li>
                            <li>{t("blog.par6")}</li>
                        </ul>
                    </article>
                    <Donate/>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default Blog;