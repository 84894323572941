import { Button } from "react-bootstrap";
import "./donate.css"


const Donate = () =>{
    return(
        <>
        <div style={{ fontFamily: 'Poppins' }}>
            <strong>Wow, I like this.</strong>
            <video autoPlay loop muted style={{ width: '50px' }} >
                    <source src={require("../../myAssets/blogHeart.mp4")} type="video/mp4" />
            </video> 
            <p>I like it too! You can share this app with your friends, family, dogs and cats.</p>


    
            <Button
                variant="dark"
                className="button"> 
                <a href="https://ko-fi.com/volhayalloo"  > You can also buy me a cup of tea🍵</a>
            </Button>

        </div>
        </>
    )
}
export default Donate