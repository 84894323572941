//bootstrap
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import LanguageDropdown from './LanguageDropdown';
import LoginButton from '../UserAccount/LoginButton';
import LogoutButton from '../UserAccount/LogoutButton';

import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from "react-i18next"


import logo from "../../../src/myAssets/Logo.png"

function NavbarYG() {

    //состояния для закрытия меню после выбора пути
    const [expanded, setExpanded] = useState(false)
    //функция выбора в меню
    const handleSelect = () => setExpanded(false);

    const { t } = useTranslation()


    return (
        <Navbar collapseOnSelect expand="lg" bg="light" className="bg-body-tertiary" expanded={expanded}>
            <Container fluid>
                <Navbar.Brand as={Link} to="/" 
                            className="order-1 order-lg-1"> 
                            <img src={logo} alt="logo" width="60px"/> 
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" 
                            className="order-3 order-lg-2"  
                            onClick={() => setExpanded(!expanded)}/>

                <Navbar.Collapse id="responsive-navbar-nav" 
                                className="order-4 order-lg-3" 
                                onSelect={handleSelect}>
                    <Nav className='ms-auto ' >

                        <Nav.Link as={Link} to="/aboutyogagirls" className='navbar-nav me-3 FontSecond' onClick={handleSelect}> {t("navbar.aboutButton")}</Nav.Link>
                        <Nav.Link as={Link} to="/blog" className='navbar-nav me-3 FontSecond' onClick={handleSelect}> {t("navbar.blogButton")} </Nav.Link>
                        <Nav.Link as={Link} to="/membership" className='navbar-nav me-3 FontSecond' onClick={handleSelect}> {t("navbar.membershipButton")} </Nav.Link>
                        <Nav.Link as={Link} to="/searchasana" className='navbar-nav me-3 FontSecond' onClick={handleSelect}> {t("navbar.catalogButton")} </Nav.Link>
                        <Nav.Item className='navbar-nav me-3 FontSecond'> <LanguageDropdown onSelect={handleSelect} /></Nav.Item>

                    </Nav>
                </Navbar.Collapse>

                <Navbar.Text className="ms-auto order-2 order-lg-4 FontSecond">
                    <LoginButton />
                    <LogoutButton />
                </Navbar.Text>
                
            </Container>
        </Navbar>
    );
}


export default NavbarYG;