import AboutYogaAsana from './Components/AboutYogaAsana';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  //Link
} from "react-router-dom";
import SearchAsana from './Components/SearchAsana';
import Lesson from './Components/SequenceBuilder/Lesson';
import Player from "./Components/PlayYourLesson/Player"
import Homepage from './Components/Website/Homepage';
//import LoginButton from './Components/UserAccount/LoginButton';
//import LogoutButton from './Components/UserAccount/LogoutButton';
import UserAccount from './Components/UserAccount/UserAccount';
import { useAuth0 } from "@auth0/auth0-react";
import NavbarYG from './Components/Website/NavbarYG';
import About from './Components/Website/About';
import Membership from './Components/Website/Membership';
import Loader from './Components/Website/Loader';
import Blog from './Components/Website/Blog';


function App() {
  const {isLoading} = useAuth0();

  //отображай лоадер, если грузятся данные
  if (isLoading) return <Loader />


  return (
    < >
      <Router>
        <nav>
          <NavbarYG />
          
        </nav>
        
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/aboutyogagirls" element={ <About /> }  />
          <Route path="/blog" element={ <Blog /> }  />
          <Route path="/useraccount" element={ <UserAccount/> }  />
          <Route path="/searchasana" element={<SearchAsana />} />
          <Route path="/lesson" element={<Lesson/>} />
          <Route path='/play' element={<Player/>} />
          <Route path="/membership" element={<Membership/>} />
          <Route path="/about/:title" element={<AboutYogaAsana/>} />
        </Routes>

      </Router>
      
    </>
  );
}

export default App;
